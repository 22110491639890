import React, { Component } from "react";
import { ReactComponent as Miniarrowright } from "../components/svgs/mini-arrow-right.svg";

import { ReactComponent as Settings } from "../components/svgs/settings.svg";
import { ReactComponent as CloseRound } from "../components/svgs/close-round.svg";
import { ReactComponent as Likde } from "../components/svgs/liked.svg";

import "./NavMenu.css";
import "./Storage.css";

export class MyStorage extends Component {
  constructor() {
    super();
    this.state = {
      userData: null,
      userStorage: null,
      showForm: false,
    };

    localStorage.removeItem("newItmeHash");
  }
  
  async handleUploadStorage() {
    window.location.href = "/storage/upload";
  }

  async handleAddStorage() {
    window.location.href = "/storage/add";
  }

  async getPersonData(hash) {
    const URLHASH = "/api/client/" + hash + "/cabinet";
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ userData: data });
      })
      .catch((error) => console.error(error));
  }

  async getUserStorage(hash) {
    const URLHASH = "/api/storage/" + hash;
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        this.setState({ userStorage: data });
      })
      .catch((error) => console.error(error));
  }

  async componentDidMount() {
    const hash = localStorage.getItem("sessionId");
    this.getPersonData(hash);
    this.getUserStorage(hash);
  }

  render() {

    function DeleteCard(hash) {
      console.log(hash);
    }

    function EditCard(hash) {
      console.log(hash);
    }

    function Storage(json) {
      return (
        <>
          <div className="storage-container">
            {json.map((x) => (
              <>
                <div className="storage-card">
                  <div className="storage-card-manager">
                    <Settings
                      title="Изменить карточку"
                      onClick={() => EditCard(x.hash)}
                      className="storage-card-settings"
                    />
                    <CloseRound
                      title="Удалить карточку"
                      onClick={() => DeleteCard(x.hash)}
                      className="storage-card-close"
                    />
                  </div>
                  <div className="storage-card-photo">
                    <img
                      className="storage-card-photo"
                      src={"Files/" + x.hash + "/1.jpeg"}
                    />
                  </div>
                  <div className="storage-card-cont">
                    <span className="storage-card-price-sale">
                      {x.priceSale} ₽/шт
                    </span>
                    <span className="storage-card-price">{x.price} ₽/шт</span>
                    <Likde className="storage-card-liked" />
                  </div>

                  <h5 className="storage-card-ownner">{x.ownerName}</h5>
                  <label className="storage-card-name">{x.name}</label>
                </div>
              </>
            ))}
          </div>
        </>
      );
    }

    const { userData } = this.state;
    const { userStorage } = this.state;

    return (
      <>
        <div className="window">
          <div className="window-content">
            {" "}
            <div className="pers-nav-body">
              <a className="nav" href="/">
                Главная
              </a>

              <Miniarrowright className="arrow-right" />
              <a className="nav" href="/storage">
                Мой склад
              </a>
            </div>
            <div className="storage-bar mt20">
              <button className="storage-mini-button" 
                onClick={() => this.handleUploadStorage()}>Загрузить файл</button>
            </div>
            {userData && (
              <div className="pers-header">
                <h1 className="text-page bold">
                  Добро пожаловать на ваш склад, {userData.FirstName}
                </h1>
                <a
                  href="/storage/add"
                  className="storage-button"
                  onClick={() => this.getNewItemHash()}
                >
                  Добавить товар
                </a>
              </div>
            )}
            {userStorage ? Storage(userStorage) : null}
          </div>
        </div>
      </>
    );
  }
}
