import React from 'react';
import { ReactComponent as Galka } from "../components/svgs/galka.svg";

const OverlayImage = () => {
    return (
        <div className="overlay-image">
            <Galka />
        </div>
    );
}

export default OverlayImage;
