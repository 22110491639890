import React, { Component } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { ReactComponent as LogoType } from "../components/svgs/logotype-trade-mate.svg";
import { ReactComponent as SearchSvg } from "../components/svgs/dd.svg";

import { ReactComponent as Geo } from "./svgs/Geo.svg";
import { ReactComponent as Metka } from "./svgs/adress.svg";

import { Registration } from "./Registration";
import Burger from "./Burger";

import "./NavMenu.css";

export class NavMenu extends Component {
  state = {
    showForm: false,
    showBurger: false,
    login: null,
  };

  componentDidMount = async () => {
    let response = await fetch(
      "/api/client/check/" + localStorage.getItem("sessionId")
    )
      .then((resp) => resp.json())
      .then((x) => x);

    this.state.login = response;
    //console.log(this.state.login);
  };

  searchItems = async () => {
    const element = document.getElementById('top-suppliers');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  
    let response = await fetch("/api/tools/top/")
      .then((resp) => resp.json());
  
    this.state.login = response;
    console.log(this.state.login);
  
    response.forEach((user, index) => {
      console.log(user.name);
    });
  
    var name = document.querySelector("#searcher").value;
    //console.log(name);
  }
  

  handleRouteWait = () => {
    window.location.href = "/wait";
  };
  handleRouteLiked = () => {
    window.location.href = "/liked";
  };
  handleRoutePersonalDate = () => {
    window.location.href = "/personal-data";
  };
  handleRouteStorage = () => {
    window.location.href = "/storage";
  };

  handelShowForm = () => {
    this.setState({
      showForm: true,
    });
  };

  handelHideForm = () => {
    this.setState({
      showForm: false,
    });
  };

  handelShowBurger = () => {
    this.setState({
      showBurger: true,
    });
  };

  handelHideBurger = () => {
    this.setState({
      showBurger: false,
    });
  };

  handelSearch = () => {
    console.log("search")
    fetch("api/admin/reset")
         .then(res => res)
         .then(data => console.log(data))
         .catch((err) => {
            console.log(err.message);
         });
  };

  render() {
    return (
      <>
        <div name="NavMenu">
          <div className="header">
            <div className="geo-div">
              <Geo className="geo-flag" />
              <span className="geo-name">RUB</span>
              <Metka className="geo-metka" />
              <span className="geo-position">г.&#160;Москва&#8194;&bull;</span>
              <span className="blues">&#8194;Укажите адрес доставки</span>
            </div>
            <div className="nav-links">
              <div className="links">
                <NavLink tag={Link} className="link" to="/">
                  О сервисе
                </NavLink>
                <NavLink tag={Link} className="link" onClick={this.searchItems}>
                  Топ-поставщики
                </NavLink>
                <NavLink tag={Link} className="link" to="/faq">
                  Вопросы и ответы
                </NavLink>
                <NavLink tag={Link} className="link" to="/">
                  Контакты
                </NavLink>
              </div>
              <NavLink tag={Link} className="phone blues" to="/">
                8(800)444-55-55
              </NavLink>
            </div>
          </div>

          <div className="bottom-nav-menu">
            {this.state.showForm ? (
              <Registration handelHideForm={this.handelHideForm} />
            ) : null}

            <div className="window-header">
              {this.state.showBurger ? (
                <button
                  className="burger-close"
                  onClick={this.handelHideBurger}
                />
              ) : (
                <button
                  className="burger-menu"
                  onClick={this.handelShowBurger}
                />
              )}

              <a href="/">
                <LogoType className="logotype" />
              </a>
              <input className="global-search" id="searcher" />
              <SearchSvg className="global-search-but" onClick={() => this.handelSearch()}/>
              <div className="div-icons">


                <button
                  onClick={localStorage.getItem("sessionId") ? this.handleRoutePersonalDate : this.handelShowForm}
                  value={"client"}
                  className="button-icon client"
                />

                <button
                  className="button-icon liked"
                  onClick={this.handleRouteLiked}
                />

                <button
                  className="button-icon order"
                  onClick={
                    localStorage.getItem("sessionId")
                      ? this.handleRouteStorage
                      : this.handelShowForm
                  }
                  value={"client"}
                />
                <button
                  className="button-icon basket"
                  onClick={this.handleRouteWait}
                />
              </div>
            </div>

            {this.state.showBurger ? (
              <Burger handelHideBurger={this.handelHideBurger} />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}
