import { ReactComponent as Arrow } from "../components/svgs/burger-arrow.svg";
import "../components/css/burger.css";
import React, { useState } from "react";
import Category from "./Category.json";
import { ReactComponent as BurgerChem } from "../components/svgs/burger-chem.svg";
import { ReactComponent as BurgerCosmet } from "../components/svgs/burger-cosmet.svg";
import { ReactComponent as BurgerEat } from "../components/svgs/burger-eat.svg";
import { ReactComponent as BurgerChild } from "../components/svgs/burger-child.svg";
import { ReactComponent as BurgerCloth } from "../components/svgs/burger-colth.svg";



const Burger = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedSubSubCategory, setSelectedSubSubCategory] = useState(null);

  const handleSubCatClick = (subCatName) => {
    setSelectedSubCategory(subCatName);
    
};

const handleSubSubCatClick = (subCatName) => {
  setSelectedSubSubCategory(subCatName);
  
};


  const GetCategory = () => {

    var libraries = JSON.parse(JSON.stringify(Category));
    //console.log(libraries);

    return (
      <div className="sub">
        {Category.map((x, index) => (
          <div key={index} className="caty">
            
            {index === 0 && <BurgerCosmet/>}
            {index === 1 && <BurgerChem/>}
            {index === 2 && <BurgerEat/>}
            {index === 3 && <BurgerChild/>}
            {index === 4 && <BurgerCloth/>}
                      
            <button className="sub-button" onClick={() => (setSelectedCategory(x.Name),handleSubCatClick(null), handleSubSubCatClick(null) )}>
              {x.Name}
            </button>
            <Arrow className="sub-arrow" />
          </div>
        ))}
      </div>
    );
  };

  const GetSubCategory = () => {
    if (!selectedCategory) return null;

    const selected = Category.find((cat) => cat.Name === selectedCategory);
    return (
      <div className="">
        {selected.SubCategorys.map((subCat, index) => (
          <div key={index} className="caty">
            <button className="sub-button" onClick={() => (handleSubCatClick(subCat.Name), handleSubSubCatClick(null))}>
              {console.log(selectedSubCategory)}
              {subCat.Name}             
            </button>
            <Arrow className="sub-arrow" />
          </div>
        ))}
      </div>
    );
    
  };

  const GetSubSubCategory = () => {
    if (!selectedSubCategory) return null; 
  
    const selectedCat = Category.find((cat) => cat.Name === selectedCategory);
    const selectedSubCat = selectedCat.SubCategorys.find(
      (subCat) => subCat.Name === selectedSubCategory
    );
    console.log("selectedCat=",selectedCat);
  
    if (!selectedSubCat || !selectedSubCat.SubCategorys) return null; 

    return (
      <div className="">
        {selectedSubCat.SubCategorys.map((subsubCat, index) => (
          <div key={index} className="caty">
            <button onClick={() => handleSubSubCatClick(subsubCat.Name)} className="sub-button">
              {subsubCat.Name}
            </button>
            
          </div>
        ))}
      </div>
    );
  
    
  };

  const GetSubSubSubCategory = () => {
    if (!selectedSubSubCategory) return null;

    const selectedCat = Category.find((cat) => cat.Name === selectedCategory);
    const selectedSubCat = selectedCat.SubCategorys.find(
      (subCat) => subCat.Name === selectedSubCategory
    );

    if (!selectedSubCat || !selectedSubCat.SubCategorys) return null;

    const selectedSubSubCat = selectedSubCat.SubCategorys.find(
      (subsubCat) => subsubCat.Name === selectedSubSubCategory
    );

    if (!selectedSubSubCat || !selectedSubSubCat.SubCategorys) return null;

    return (
      <div className="">
        {selectedSubSubCat.SubCategorys.map((subsubsubCat, index) => (
          <div key={index} className="caty">
            <button className="sub-button">{subsubsubCat.Name}</button>
          </div>
        ))}
      </div>
    );
};


  return (
    <>

      <div className="box">
        {GetCategory()}      
      <div className="sub">
        {GetSubCategory()}
      </div>
      <div className="sub">
        {GetSubSubCategory()}
      </div>
      </div>

    </>
  );
};

export default Burger;




