import { Component } from "react";

import { ReactComponent as Miniarrowright } from "../components/svgs/mini-arrow-right.svg";
export class Liked extends Component {
  render() {
    return (
      <>
        <div className="window">
          <div className="window-content">
          <div className="pers-nav-body">
              <a className="nav" href="/">
                Главная
              </a>

              <Miniarrowright className="arrow-right" />
              <a className="nav" href="/personal-data">
                Избранное
              </a>
            </div>
            <div className="pers-header">
              <h1 className="text-page bold mt30">
                Избранное
              </h1>
            </div>
          </div>
        </div>
      </>
    );
  }
}
