import React, { Component } from "react";
import { ReactComponent as Likde } from "../components/svgs/liked.svg";

import Upload from "../components/Upload";
import "./NavMenu.css";
import { Footer } from "./Footer";

export class AddStorage extends Component {
  constructor() {
    super();
    this.state = {
      userData: null,
      userStorage: null,
      inputValue: "",
      hashItem: null,
      file: null,
    };

    this.getNewItemHash();
  }

  async SetPhotos(file, hash) {
    let data = new FormData();
    data.append("files", file);
    data.append("hash", hash);

    await fetch("photoloader/upload", {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      credentials: "same-origin",
      body: data,
      // 👇 Set headers manually for single file upload
      headers: {
        "content-type": file.type,
        "content-length": `${file.size}`, // 👈 Headers need to be a string
      },
    });
  }

  getNewItemHash = () => {
    const URLHASH = "/api/client/hash";
    return fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("newItmeHash", data);
        return data;
      })
      .catch((error) => console.error(error));
  };

  handleInputChange = (e) => {
    this.setState({
      inputValue: e.target.value,
    });
  };

  async AddNewItem(nameOwner) {
    const URLREG = "/api/storage/";
    const strName = document.querySelector("#strName").value;
    const strBrand = document.querySelector("#strBrand").value;
    const strDescr = document.querySelector("#strDescr").value;
    const strType = document.querySelector("#strType").value;
    const strPrice = document.querySelector("#strPrice").value;
    const strSale = document.querySelector("#strSale").value;
    const strVolume = document.querySelector("#strVolume").value;
    const strRating = document.querySelector("#strRating").value;
    const strScore = document.querySelector("#strScore").value;

    const newItem = {
      Hash: localStorage.getItem("newItmeHash"),
      HashOwner: localStorage.getItem("sessionId"),
      Name: strName,
      Brand: strBrand,
      NameOwner: nameOwner,
      Description: strDescr,
      Type: strType,
      Price: strPrice,
      PriceSale: strSale,
      Volume: strVolume,
      Rating: strRating,
      Score: strScore,
      BlobUrl: localStorage.getItem("lastBlob"),
    };

    const options = {
      method: "POST",

      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },

      body: JSON.stringify(newItem),
    };

    const result = await fetch(URLREG, options);

    this.SetPhotos(this.state.file, localStorage.getItem("newItmeHash"));

    if (result.ok) {
      const resp = await result.json();

      if (resp === true) {
        window.location.href = "/storage";
      } else {
        console.log(resp);
        alert("Ошибка: Неудачная регистрация");
      }
    }
  }

  async getUserStorage(hash) {
    const URLHASH = "/api/storage/" + hash;
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ userStorage: data });
      })
      .catch((error) => console.error(error));
  }

  async getPersonData(hash) {
    const URLHASH = "/api/client/" + hash + "/cabinet";
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ userData: data });
      })
      .catch((error) => console.error(error));
  }

  async componentDidMount() {
    const hash = localStorage.getItem("sessionId");
    this.getPersonData(hash);
    this.getUserStorage(hash);
  }

  render() {
    function Sync() {
      let message = document.querySelector("#name").value;
      console.log(message);
    }

    const { userData } = this.state;
    const { userStorage } = this.state;

    return (
      <>
        {userData && (
          <>
            <div className="window">
              <div className="window-content">
                <div className="pers-header">
                  <h1 className="text-page bold mt30">Добавление товара</h1>
                </div>

                <div className="edit-block">
                  <div className="input-area">
                    <h5>Пример карточки</h5>
                    <br></br>
                    <div className="storage-card-upd">
                      <div className="storage-card-photo-upd">
                        {this.state.file && (
                          <img
                            className="photo"
                            alt="not found"
                            width={"100%"}
                            src={URL.createObjectURL(this.state.file)}
                          />
                        )}
                      </div>
                      <div className="storage-card-cont">
                        <span className="storage-card-price-sale">
                          {"Цена"} ₽/шт
                        </span>
                        <span className="storage-card-price">
                          {"Цена"} ₽/шт
                        </span>
                        <Likde className="storage-card-liked" />
                      </div>

                      <h5 className="storage-card-ownner">{"Имя владельца"}</h5>
                      <label className="storage-card-name">
                        {"Имя владельца"}
                      </label>
                    </div>
                  </div>

                  <div className="input-area">
                    <h5>1. Заполните поля</h5>
                    <br></br>
                    <input
                      className="input-edit"
                      id="strName"
                      defaultValue=""
                      placeholder="Название"
                      onChange={() => Sync()}
                    />
                    <input
                      className="input-edit"
                      id="strBrand"
                      defaultValue=""
                      placeholder="Бренд"
                    />
                    <input
                      className="input-edit"
                      id="strDescr"
                      defaultValue=""
                      placeholder="Описание"
                    />
                    <input
                      className="input-edit"
                      id="strType"
                      defaultValue=""
                      placeholder="Тип"
                    />
                    <input
                      className="input-edit"
                      id="strPrice"
                      defaultValue=""
                      placeholder="Цена"
                    />
                    <input
                      className="input-edit"
                      id="strSale"
                      defaultValue=""
                      placeholder="Цена со скидкой"
                    />
                    <input
                      className="input-edit-preamount"
                      id="strVolume"
                      defaultValue=""
                      placeholder="Количество"
                    />
                    <input
                      className="input-edit-amount"
                      id="strVolume"
                      type="text"
                      value={this.state.inputValue}
                      onChange={this.handleInputChange}
                      list="units"
                    />
                    <datalist id="units">
                      <option value="шт" />
                      <option value="литр" />
                      <option value="кг" />
                    </datalist>

                    <input
                      className="input-edit"
                      id="strRating"
                      defaultValue=""
                      placeholder="Рейтинг"
                    />

                    <input
                      className="input-edit"
                      id="strScore"
                      defaultValue=""
                      placeholder="Оценка"
                    />

                    <button
                      className="storage-button-new"
                      onClick={() => this.AddNewItem(userData.name)}
                    >
                      Добавить
                    </button>
                  </div>

                  <div className="input-area">
                    <h5>2. Выбeрите изображение</h5>
                    <br></br>
                    <label className="storage-button-new">
                      Выбрaть фото
                      <input
                        className="input-file"
                        type="file"
                        name="file"
                        onChange={(event) => {
                          this.setState({ file: event.target.files[0] });
                        }}
                      />
                    </label>
                  </div>
                  {/* 
                  <Upload ownerName={userData.name} className="upload" />
                  <div className="edit-area">
                    <h5>2. Заполните поля</h5>
                    <br></br>
                    <input
                      className="input-edit"
                      id="strName"
                      defaultValue=""
                      placeholder="Название"
                      onChange={() => Sync()}
                    />
                    <input
                      className="input-edit"
                      id="strBrand"
                      defaultValue=""
                      placeholder="Бренд"
                    />
                    <input
                      className="input-edit"
                      id="strDescr"
                      defaultValue=""
                      placeholder="Описание"
                    />
                    <input
                      className="input-edit"
                      id="strType"
                      defaultValue=""
                      placeholder="Тип"
                    />
                    <input
                      className="input-edit"
                      id="strPrice"
                      defaultValue=""
                      placeholder="Цена"
                    />
                    <input
                      className="input-edit"
                      id="strSale"
                      defaultValue=""
                      placeholder="Цена со скидкой"
                    />
                    <input
                      className="input-edit-preamount"
                      id="strVolume"
                      defaultValue=""
                      placeholder="Количество"
                    />
                    <input
                      className="input-edit-amount"
                      id="strVolume"
                      type="text"
                      value={this.state.inputValue}
                      onChange={this.handleInputChange}
                      list="units"
                    />
                    <datalist id="units">
                      <option value="шт" />
                      <option value="литр" />
                      <option value="кг" />
                    </datalist>

                    <input
                      className="input-edit"
                      id="strRating"
                      defaultValue=""
                      placeholder="Рейтинг"
                    />

                    <input
                      className="input-edit"
                      id="strScore"
                      defaultValue=""
                      placeholder="Оценка"
                    />

                    <button
                      className="storage-button-new"
                      onClick={() =>
                        this.AddNewItem(
                          userData.name
                        )
                      }
                    >
                      Добавить
                    </button>
                  </div>
                  */}
                </div>
              </div>
            </div>
            <Footer />
          </>
        )}
      </>
    );
  }
}
