import React, { useState, useEffect } from "react";
import "../components/css/filter.css";

import { ReactComponent as Likde } from "../components/svgs/liked.svg";
import { ReactComponent as ArrowDown } from "../components/svgs/arrow-down-2.svg";
import OverlayImage from "./OverlayImage";
import ImageFlip from "./ImageFlip";

const Filter = ({ userStorage }) => {
  const prices = userStorage.map((item) => item.priceSale);
  const minValue = Math.min(...prices);
  const maxValue = Math.max(...prices);

  const [minPrice, setMinPrice] = useState(minValue);
  const [maxPrice, setMaxPrice] = useState(maxValue);
  const [brands, setBrands] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [clickArrowPrice, setClickArrowPrice] = useState(false);
  const [clickArrowBrand, setClickArrowBrand] = useState(false);
  const [clickArrowDelivery, setClickArrowDelivery] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  function handleMinPriceChange(event) {
    const input = event.target;
    const value = input.value;

    // Проверка на ввод чисел или пустой строки
    if (/^\d*$/.test(value) || value === "") {
      setMinPrice(value);
    }
  }

  function handleMaxPriceChange(event) {
    const input = event.target;
    const value = input.value;

    // Проверка на ввод чисел или пустой строки
    if (/^\d*$/.test(value) || value === "") {
      setMaxPrice(value);
    }
  }

  const getAllBrands = async () => {
    const URLHASH = "/api/storage/brands";

    try {
      const response = await fetch(URLHASH);
      if (!response.ok) {
        throw new Error("Failed to fetch brands");
      }

      let data = await response.json();
      //console.log(data);
      return data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  useEffect(() => {
    getAllBrands().then((data) => setBrands(data));
  }, []);

  const handleClickOutside = (event) => {
    if (event.target.classList.contains("filter-arrow")) {
      setClickArrowPrice(false);
      setClickArrowBrand(false);
      setClickArrowDelivery(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const toggleClickArrowPrice = (event) => {
    event.stopPropagation();
    setClickArrowPrice(!clickArrowPrice);
  };

  const toggleClickArrowDelivery = (event) => {
    event.stopPropagation();
    setClickArrowDelivery(!clickArrowDelivery);
  };

  const toggleClickArrowBrand = (event) => {
    event.stopPropagation();
    setClickArrowBrand(!clickArrowBrand);
  };

  function StorageBrand() {
    const handleBrandCheckboxChange = (brand) => {
      if (selectedBrands.includes(brand)) {
        setSelectedBrands(selectedBrands.filter((b) => b !== brand));
      } else {
        setSelectedBrands([...selectedBrands, brand]);
      }
    };

    let i = 0;
    return (
      <div>
        {brands.map((brand) => (
          <label className="checkbox" key={brand}>
            <label className="custom-checkbox">
              <input
                className="custom-check"
                type="checkbox"
                id="customCheckbox"
                onChange={() => handleBrandCheckboxChange(brand)}
                checked={selectedBrands.includes(brand)}
              />

              <span className="checkbox-icon">
                {selectedBrands.includes(brand) && <OverlayImage />}
              </span>
              <span className="checkbox-label">{brand}</span>
            </label>
          </label>
        ))}
      </div>
    );
  }

  function MainFilter() {
    const filteredByPrice = userStorage.filter(
      (item) => item.priceSale >= minPrice && item.priceSale <= maxPrice
    );

    return filteredByPrice.filter(
      (item) =>
        selectedBrands.length === 0 || selectedBrands.includes(item.brand)
    );
  }

  function StorageCard() {
    //console.log(userStorage);
    const filteredStorage = MainFilter();

    let i = 0;
    return (
      <div className="storage-container">
        {filteredStorage.length > 0 ? (
          filteredStorage.map((x) => (
            <div className="storage-card" key={x.id}>
              <div className="storage-card-photo">
                <img
                  className="storage-card-photo"
                  src={"Files/" + x.hash + "/1.jpeg"}
                />
              </div>
              <div className="storage-card-cont">
                <span className="storage-card-price-sale">
                  {x.priceSale} ₽/шт
                </span>
                <span className="storage-card-price">{x.price} ₽/шт</span>
                <Likde className="storage-card-liked" />
              </div>
              <h5 className="storage-card-ownner">{x.ownerName}</h5>
              <label className="storage-card-name">{x.name}</label>
              <div>
                <label className="storage-card-brand">{x.brand}</label>
              </div>
            </div>
          ))
        ) : (
          <div className="filter-warning">Товаров по фильру не найдено</div>
        )}
      </div>
    );
  }

  return (
    <>
      <div className="filter">
        <h2 className="text-page bold mb0">Фильтры:</h2>

        <div className="filter-cont mt20 mb0">
          <label className="filter-name bold">Цена, ₽</label>
          <div className="filter-arrow" onClick={toggleClickArrowPrice}>
            <ImageFlip />
          </div>

          <div className="filter-price mt10"></div>
          {clickArrowPrice ? (
            <div className="filter-price">
              <label className="filter-price-label">От</label>
              <label className="filter-price-label">До</label>
              <input
                className="filter-price-input"
                id="min"
                value={minPrice}
                placeholder={minPrice}
                onChange={handleMinPriceChange}
              />
              <input
                className="filter-price-input"
                id="max"
                value={maxPrice}
                placeholder={maxPrice}
                onChange={handleMaxPriceChange}
              />
            </div>
          ) : null}
        </div>

        <div className="filter-cont mt20 mb0">
          <label className="filter-name bold">Поставщики</label>

          <div className="filter-arrow" onClick={toggleClickArrowDelivery}>
            <ImageFlip />
          </div>

          <div className="filter-price mt10"></div>
          {clickArrowDelivery ? <StorageBrand /> : null}
        </div>

        <div className="filter-cont mt20 mb0">
          <label className="filter-name bold">Бренд</label>

          <div className="filter-arrow" onClick={toggleClickArrowBrand}>
            <ImageFlip />
          </div>

          <div className="filter-price "></div>
          {clickArrowBrand ? <StorageBrand /> : null}
        </div>
      </div>
      {<StorageCard userStorage={MainFilter()} />}
    </>
  );
};

export default Filter;
