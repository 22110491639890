import { Component } from "react";

export class UploadTable extends Component {
  
  async handleUploadClick(file) {
    if (!file) {
      return;
    }

    let data = new FormData();
    data.append("file", file);
    data.append("hash", localStorage.getItem("sessionId"));
    

    // 👇 Uploading the file using the fetch API to the server
    fetch("/api/storage/upload", {
      method: "POST",
      mode: "no-cors",
      cache: "no-cache",
      credentials: "same-origin",
      body: data,
      // 👇 Set headers manually for single file upload
      headers: {
        "content-type": file.type,
        "content-length": `${file.size}`, // 👈 Headers need to be a string
      },
    })
      //.then((res) => res.json())
      .then((data) => console.log(data))
      .catch((err) => console.error(err));
  }

  render() {
    const styles = {
      block: {
        width: "100%",
        minHeight: "500px",
        display: "inline-block",
      },
      button: {
        paddingTop: "11px",
        marginTop: "40px",
        borderRadius: "6px",
        border: "0",
        position: "relative",
        left: "50%",
        transform: "translate(-50%,0)",
        paddingLeft: "45px",
        paddingRight: "45px",
        height: "55px",
        fontSize: "22px",
        background: "#538EFF",
        color: "white",
      },
    };
    return (
      <>
        <div>
          <h1 className="text-page bold mt50">У вас нет загруженных таблиц</h1>
          <div style={styles.block}>
            <label style={styles.button}>
              Загрузить
              <input 
              type="file" 
              hidden={true}
              onChange={(event) => {
                console.log(event.target.files[0]);
                this.handleUploadClick(event.target.files[0])
              }}
              />
            </label>
          </div>
        </div>
      </>
    );
  }
}
