import React, { Component } from "react";
import { ReactComponent as LogoType } from "../components/svgs/logotype-trade-mate.svg";

export class Footer extends Component {
  render() {
    return (
      <div className="footer">
        <div className="footer-cont">
          <div className="list-footer">
            <a href="#" className="b">
              Партнерам
            </a>
            <br />
            <a href="#">Стать поставщиком</a>
            <br />
            <a href="#">Справка</a>
            <br />
            <a href="#">
              Партнерская
              <br />
              программа
            </a>
          </div>

          <div className="list-footer">
            <a href="#" className="b">
              Клиентам
            </a>
            <br />
            <a href="#">Топ-поставщики</a>
            <br />
            <a href="#">Мои заказы</a>
            <br />
            <a href="#">Политика возврата</a>
            <br />
            <a href="#">Вопросы и ответы</a>
            <br />
          </div>

          <div className="list-footer">
            <a href="#" className="b">
              Информация
            </a>
            <br />
            <a href="#">О сервисе</a>
            <br />
            <a href="#">Контакты</a>
            <br />
            <a href="#">Реквизиты</a>
            <br />
            <a href="#">Создатели</a>
            <br />
          </div>

          <LogoType className="logo-footer" />
        </div>
      </div>
    );
  }
}
