import React, { useState } from 'react';
import ArrowDown from "../components/svgs/arrow-down-2.svg";

const ImageFlip = () => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    setIsFlipped(!isFlipped);
  };

  return (
    <div className="image-container" onClick={handleClick}>
      <img src={ArrowDown} alt="Your Image" className={isFlipped ? 'flipped' : ''} />
    </div>
  );
};

export default ImageFlip;
