//#region imports
import { Component } from "react";
import { Footer } from "./Footer";

import { ReactComponent as Miniarrowright } from "../components/svgs/mini-arrow-right.svg";

import "./Faq.css";
import "../components/css/main.css";
import "../components/css/spec.css";
import "../components/css/fawor.css";
import "../components/css/burger.css";
import "../components/css/category.css";
import "../components/css/global-search.css";
import { Question } from "./Question";

//#endregion

export class Faq extends Component {
  render() {


    return (
      <>
        <div className="window">
          <div className="window-content">
            <div className="nav-body"> 
              <a className="nav" href="/">
                Главная
              </a>

              <Miniarrowright className="arrow-right" />

              <a className="nav" href="/faq">Вопросы и ответы</a>
            </div>

            <div className="body">
              <h3 className="question-header">Ответы на часто-задаваемые вопросы</h3>
              <div className="question-block">
                <h3 className="question-text-page">Оформление заказа</h3>

                <Question question="Какая минимальная сумма заказа?" answer="Минимальная сумма заказа определяется у каждого поставщика отдельно. Эта информация есть в карточке поставщика или в каталоге." />
                <Question question="Способы оформления заказа" />
                <Question question="Что делать, если у поставщика нет товара в наличии?" />
                <Question question="Что происходит после оформления заказа?" />
                <Question question="Как осуществляется возврат товара?" />
                <Question question="Где посмотреть историю заказов?" />
                <Question question="Могу ли я повторить предыдущий заказ?" />
                <Question question="Как можно отменить заказ?" />
              </div>

              <div className="question-block">
                <h3 className="question-text-page">Доставка</h3>
                <Question question="Способы оформления заказа" />
                <Question question="Что делать, если у поставщика нет товара в наличии?" />
                <Question question="Что происходит после оформления заказа?" />
                <Question
                  question="Какая минимальная сумма заказа?"
                  answer="Минимальная сумма заказа определяется у каждого поставщика отдельно. Эта информация есть в карточке поставщика или в каталоге."
                />
                <Question question="Как осуществляется возврат товара?" />
                <Question question="Где посмотреть историю заказов?" />
              </div>


            </div>
            <div className="question-block">
              <h3 className="question-text-page">Доставка</h3>
              <Question question="Способы оформления заказа" />
              <Question question="Что делать, если у поставщика нет товара в наличии?" />
              <Question question="Что происходит после оформления заказа?" />
              <Question
                question="Какая минимальная сумма заказа?"
                answer="Минимальная сумма заказа определяется у каждого поставщика отдельно. Эта информация есть в карточке поставщика или в каталоге."
              />
              <Question question="Как осуществляется возврат товара?" />
              <Question question="Где посмотреть историю заказов?" />
            </div>

            <div className="question-block">
              <h3 className="question-text-page">Доставка</h3>
              <Question question="Способы оформления заказа" />
              <Question question="Что делать, если у поставщика нет товара в наличии?" />
              <Question question="Что происходит после оформления заказа?" />
              <Question
                question="Какая минимальная сумма заказа?"
                answer="Минимальная сумма заказа определяется у каждого поставщика отдельно. Эта информация есть в карточке поставщика или в каталоге."
              />
              <Question question="Как осуществляется возврат товара?" />
              <Question question="Где посмотреть историю заказов?" />
            </div>

          </div>
          <Footer />
        </div>

      </>
    );
  }
}
