import { Component } from "react";

export class Question extends Component {
  state = {
    showResp: false,
    showCross: false,
  };

  handelShowForm = () => {
    this.setState({
      showResp: true,
      showCross: true,
    });
  };
  
  handelHideForm = () => {
    this.setState({
      showResp: false,
      showCross: false,
    });
  };

  render() {
    const question = this.props.question;
    const answer = this.props.answer;
    return (
      <div className="question-body">
        <div className="question-body-header">{question}</div>
        {this.state.showCross ? (
          <button className="cross" onClick={this.handelHideForm} />
        ) : (
          <button className="plus" onClick={this.handelShowForm} />
        )}

        {this.state.showResp ? (
          <div className="line">
            <span className="question-body-response">{answer}</span>
          </div>
        ) : null}
      </div>
    );
  }
}
