//#region imports
import React, { Component } from "react";
import avatar from "../components/special/test.png";
import { ReactComponent as BoxedSvg } from "../components/svgs/box.svg";
import { ReactComponent as TopSvg } from "../components/svgs/top.svg";
import { ReactComponent as Text } from "../components/svgs/text.svg";
import { ReactComponent as DrinkSvg } from "../components/svgs/drink.svg";
import { ReactComponent as TextII } from "../components/svgs/text-2.svg";
import { ReactComponent as Notebook } from "../components/svgs/notebook.svg";
import { ReactComponent as ListPost } from "../components/svgs/button-ad.svg";
import { ReactComponent as CategoryChild } from "../components/svgs/category-child.svg";
import { ReactComponent as CategoryEat } from "../components/svgs/category-eat.svg";
import { ReactComponent as CategoryBeuty } from "../components/svgs/category-beuty.svg";
import { ReactComponent as CategoryChym } from "../components/svgs/category-chym.svg";
import { ReactComponent as CategoryColth } from "../components/svgs/category-colth.svg";
import { ReactComponent as ClikcSvg } from "../components/svgs/clickAd.svg";
import { ReactComponent as BannerSvg } from "../components/svgs/banner.svg";

import "../components/css/main.css";
import "../components/css/spec.css";
import "../components/css/fawor.css";
import "../components/css/burger.css";
import "../components/css/category.css";
import "../components/css/global-search.css";
import { Footer } from "./Footer";
import { CookieBanner } from "./Cookie";
import { Link, Route } from "react-router-dom";
import { Catalog } from "./Catalog";
//#endregion

export class Home extends Component {
  state = {
    showForm: false,
    showBurger: false,
    showCookie: false,
  };

  handelShowForm = () => {
    this.setState({
      showForm: true,
    });
  };

  handelHideForm = () => {
    this.setState({
      showForm: false,
    });
  };

  handelShowBurger = () => {
    this.setState({
      showBurger: true,
    });
  };

  handelHideBurger = () => {
    this.setState({
      showBurger: false,
    });
  };

  componentDidMount() {
    const cookieShown = localStorage.getItem("cookieShown");
    if (!cookieShown) {
      this.setState({ showCookie: true }); // Показываем баннер, если его еще не было
      localStorage.setItem("cookieShown", true); // Устанавливаем значение в локальное хранилище
    }

    this.searchItems();
  }


  searchItems = async () => {
    let response = await fetch("/api/tools/top/").then((resp) => resp.json());

    response.forEach((user, index) => {
      if (index === 0) {
        var top1 = user.name;
        var photo1 = 'data:image/png;base64,' + user.photo;


        var img = document.createElement('img');
        img.src = photo1;


        document.getElementById('photos').appendChild(img);

        this.setState({ top1, photo1 });
      }
      if (index === 1) {
        var top2 = user.name;
        var photo2 = 'data:image/png;base64,' + user.photo;


        var img = document.createElement('img');
        img.src = photo2;


        document.getElementById('photos').appendChild(img);

        this.setState({ top2, photo2 });
      }
      if (index === 2) {
        var top3 = user.name;
        var photo3 = 'data:image/png;base64,' + user.photo;


        var img = document.createElement('img');
        img.src = photo3;


        document.getElementById('photos').appendChild(img);

        this.setState({ top3, photo3 });
      }

    });

    var name = document.querySelector("#searcher").value;
    //console.log(name);
  }





  render() {
    const { showCookie } = this.state;
    const { top1, top2, top3, photo1, photo2, photo3 } = this.state;

    function MiniBar(ftxt, stxt, svg, color) {
      return (
        <div className="spec-cont" style={{ background: color }}>
          <h3 className="spec-cont-header">{ftxt}</h3>
          <h5 className="spec-cont-body">{stxt}</h5>
          {svg}
          <a href="/faq">
            <ClikcSvg className="spec-cont-pic-click" />
          </a>
        </div>
      );
    }

    function CategoryBar(txt, svg, url) {
      return (
        <Link to={"catalog/"} state={url} className="caty-url">
          <div className="caty-cont">
            {" "}
            {svg} <h5>{txt}</h5>{" "}
          </div>
        </Link>
      );
    }

    return (
      <div className="window">
        <div className="window-content">
          <BannerSvg className="banner" />

          <h1 className="text-page bold mt80">Специальные предложения</h1>
          <div className="spec-bars mt40">
            {MiniBar(
              "Выбирай опт",
              "и экономь до 30%",
              <BoxedSvg className="abs" />,
              "#163689"
            )}
            {MiniBar("Товары со скидкой", "", null, "#4881D8")}
            {MiniBar(
              "Напитки",
              "от лучших поставщиков",
              <DrinkSvg className="abs" />,
              "#64CC27"
            )}
            {MiniBar("Как пользоваться сервисом", "", null, "#97CDFF")}
          </div>

          <h1 className="text-page bold mt80">Категории товаров</h1>
          <div className="caty-bars">
            {CategoryBar(
              "Косметика",
              <CategoryBeuty className="caty-img" />,
              "cosmetic"
            )}
            {CategoryBar(
              "Бытовая химия",
              <CategoryChym className="caty-img" />,
              "chemistry"
            )}
            {CategoryBar(
              "Продукты питания",
              <CategoryEat className="caty-img" />,
              "eat"
            )}
            {CategoryBar(
              "Товары для детей",
              <CategoryChild className="caty-img" />,
              "kid"
            )}
            {CategoryBar(
              "Одежда и обувь",
              <CategoryColth className="caty-img" />,
              "colth"
            )}
          </div>

          <h1 className="text-page bold mt80" id="top-suppliers">Наши лучшие поставщики</h1>
          <h3 className="text-page-sec">ТОП-3 этого месяца:</h3>
          <div className="fawor">
            <div className="fawor-first">
              <img src={photo2} id='photos' className="avatar-img" />
              <div className="top">
                {top2}
              </div>
            </div>

            <div className="fawor-second">
              <img src={photo1} id='photos' className="avatar-img" />
              <div className="top">
                {top1}
              </div>
            </div>

            <div className="fawor-trith">
              <img src={photo3} id='photos' className="avatar-img" />
              <div className="top">
                {top3}
              </div>
            </div>

            <TopSvg className="fawor-svg" />
          </div>

          <h3 className="text-page bold mt80">
            Как мы выбираем поставщиков для сотрудничества:
          </h3>
          <div className="board">
            <Text className="text-svg" />
          </div>
          <a>
            <ListPost className="button" />
          </a>

          <h1 className="text-page bold mt80">Почему стоит выбрать именно нас?</h1>

          <div className="maxed-content">
            <h3 className="box-header">TradeMate - это торговый помощник</h3>
            <TextII className="box-body" />
            <Notebook className="box-img b25" />
          </div>
        </div>
        {showCookie && <CookieBanner />}
        <Footer />
      </div>
    );
  }
}
