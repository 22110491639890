import { ReactComponent as LogoType } from "../components/svgs/logotype-trade-mate.svg";
import { useState } from "react";
import "../components/css/registration.css";

export const Registration = ({ handelHideForm }) => {
  const [state, setState] = useState("store");
  const [login, setLogin] = useState(true);

  const getNewHash = async () => {
    const URLHASH = "/api/client/hash";
    return fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("sessionId", data);
        return data;
      })
      .catch((error) => console.error(error));
  };

  const CreateUser = async () => {
    const URLREG = "/api/client/registration";
    const URLIP = "/api/tools/" + localStorage.getItem("sessionId");
    const HASH = await getNewHash();
    const IP = await fetch(URLIP)
      .then((response) => {
        return response;
      })
      .then((x) => console.log(x));

    const phone = document.querySelector("#phone").value;
    const pass = document.querySelector("#pass").value;

    const newClient = {
      ip: IP,
      password: pass,
      phone: phone,
      hash: HASH,
      type: state,
    };

    const options = {
      method: "POST",

      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },

      body: JSON.stringify(newClient),
    };

    const result = await fetch(URLREG, options);

    if (result.ok) {
      const resp = await result.json();

      if (resp === true) {
        console.log("Login" + HASH);
        window.location.href = "/personal-data";
      } else {
        console.log(resp);
        alert("Ошибка: Неудачная регистрация");
      }
    }
  };

  const LoginUser = async () => {
    const URLLOGIN = "/api/client/login";
    const URLIP = "/api/tools/ip";
    const HASH = await getNewHash();
    const IP = await fetch(URLIP)
      .then((response) => {
        return response;
      })
      .then((x) => console.log(x));

    const phone = document.querySelector("#phone").value;
    const pass = document.querySelector("#pass").value;

    const client = {
      hash: HASH,
      ip: IP,
      password: pass,
      phone: phone,
    };

    const options = {
      method: "POST",

      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },

      body: JSON.stringify(client),
    };

    const result = await fetch(URLLOGIN, options);

    if (result.ok) {
      const resp = await result.json();

      if (resp === true) {
        console.log("Login");
        window.location.href = "/personal-data";
      } else {
        console.log(resp);
        alert("Ошибка: Неудачный вход");
      }
    }
  };

  const isPhoneValid = () => {
    let message = document.querySelector("#phone").value;

    message = message.replace("+7", "");
    message = message.replace("+", "");

    let chars = message.split("");
    if (chars[0] === "8") {
      console.log("8");
      chars[0] = "";
    }

    message = chars.join("");
    document.querySelector("#phone").value = message;
  };

  const isPasswordValid = () => {
    let message = document.querySelector("#phone").value;
    let pass = document.querySelector("#pass").value;

    if (pass !== "") {
      console.log(message);
      console.log(pass);
    }
  };

  function questRed(qst) {
    setState(qst);
  }

  function Quests() {
    if (state == "store") {
      return (
        <>
          <button className="reg-quest green" onClick={() => questRed("store")}>
            Магазин
          </button>
          <button className="reg-quest" onClick={() => questRed("supller")}>
            Поставщик
          </button>
        </>
      );
    } else {
      return (
        <>
          <button className="reg-quest" onClick={() => questRed("store")}>
            Магазин
          </button>
          <button
            className="reg-quest green"
            onClick={() => questRed("supller")}
          >
            Поставщик
          </button>
        </>
      );
    }
  }

  function LoginOrReg() {
    if (login) {
      return (
        <>
          <h2 className="text-head">Введите номер телефона</h2>
          <div>
            <input
              className="pre-number"
              type="text"
              placeholder="+7"
              name="clientLogin"
            />
            <input
              id="phone"
              className="reg-input-left"
              type="text"
              placeholder="Номер"
              name="clientLogin"
              onChange={isPhoneValid}
            />
          </div>

          <div>
            <input
              id="pass"
              className="log-input"
              type="password"
              placeholder="Пароль"
              name="clientLogin"
              onChange={isPasswordValid}
            />
          </div>

          <button
            id
            onClick={() => LoginUser()}
            className=" log-input button-mid-log"
            type="button"
          >
            Войти
          </button>

          <button
            onClick={() => setLogin(false)}
            className="reg-input button-mid-reg "
            type="button"
          >
            Регистрация
          </button>
        </>
      );
    } else {
      return (
        <>
          <h2 className="text-head">Выбирете тип аккаунта</h2>
          <div className="reg-quest-block">
            <Quests />
          </div>
          <h2 className="text-head">Введите номер телефона</h2>
          <div>
            <input
              className="pre-number"
              type="text"
              placeholder="+7"
              name="clientLogin"
            />
            <input
              id="phone"
              className="reg-input-left"
              type="text"
              placeholder="Номер"
              name="clientLogin"
              onChange={isPhoneValid}
            />
          </div>

          <div>
            <input
              id="pass"
              className="log-input"
              type="password"
              placeholder="Пароль"
              name="clientLogin"
              onChange={isPasswordValid}
            />
          </div>
          <button
            onClick={() => CreateUser()}
            className="reg-input button-mid-reg "
            type="button"
          >
            Зарегистрироваться
          </button>
          <button
            onClick={() => setLogin(true)}
            className="log-input button-mid-log"
            type="button"
          >
            Назад
          </button>
        </>
      );
    }
  }

  return (
    <>
      <div className="reg-content">
        <LogoType className="reg-logo" />
        <button onClick={handelHideForm} className="reg-close"></button>

        <LoginOrReg />
      </div>
      <div onClick={handelHideForm} className="overlay"></div>
    </>
  );
};
