import { Component } from "react";
import "./tablse.css";
export class Tables extends Component {
  

  
  render() {
    
    function SaveTable(e){
      console.log(e)
    }

    const tables = this.props.tabs ?? null;
    console.log(tables)
    let nameKey = 0
    let cellKey = 0
    let tableKey = 0
    return (
      <>
        <div>
          <div className="table-panel">
            
            <button className="table-panel-button">Настройки таблицы</button>
            <button className="table-panel-button">Сохранить</button>
            <button className="table-panel-button">Поделиться</button>
            <button className="table-panel-button">Скачать</button>
            <button className="table-panel-button">Получить товары</button>
            <button className="table-panel-button">Отменить изменения</button>
            <button className="table-panel-button">Назад</button>
            
          </div>
          {tables && tables.map(
            table => (

    
              <div className="table" key={tableKey++}>
                {table.headers.map(name => (<><input id={"header" + nameKey} className="table-headers" defaultValue={name} key={nameKey++}/></> ))}
                {table.data.map(row => (
                  <div className="table-row">
                    {row.map(cell => (<textarea 
                                        id={"cell" + cellKey} 
                                        className="table-cells" 
                                        defaultValue={cell} 
                                        key={cellKey++}
                                        onChange={() => console.log(this.cellKey)}
                                        />))}
                  </div>
                  ))}
              </div>
            )
            )
          }
        </div>
      </>
    );
  }
}
