import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { ReactComponent as CategoryChild } from "../components/svgs/category-child.svg";
import { ReactComponent as CategoryEat } from "../components/svgs/category-eat.svg";
import { ReactComponent as CategoryBeuty } from "../components/svgs/category-beuty.svg";
import { ReactComponent as CategoryChym } from "../components/svgs/category-chym.svg";
import { ReactComponent as CategoryColth } from "../components/svgs/category-colth.svg";
import { ReactComponent as Likde } from "../components/svgs/liked.svg";
import Filter from '../components/Filter';
import { Footer } from './Footer';
import "../components/css/main.css";


const Catalog = (props) => {

  const location = useLocation();
  const data = location.state;


  const [userData, setUserData] = useState(null);
  const [userStorage, setUserStorage] = useState(null);


  

  const getNewHash = async () => {
    // Реализация метода getNewHash
  };



  const getPersonData = async (hash) => {
    const URLHASH = "/api/client/" + hash + "/cabinet";
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        setUserData(data);
      })
      .catch((error) => console.error(error));
  };

  const getUserStorage = async (hash) => {
    const URLHASH = "/api/storage/" + hash;
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        setUserStorage(data);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const fetchData = async () => {
      const hash = localStorage.getItem("sessionId") || (await getNewHash());
      getPersonData(hash);
      getUserStorage(hash);
    };
    fetchData();
  }, []);

  function CategoryBar(txt, svg, category) {
    return (
      <Link to={"/catalog/"} state={category} className="caty-url">
        <div className="caty-cont">
          {svg}
          <h5>{txt}</h5>
        </div>
      </Link>
    );
  }

  function UnderCategory(txt, category) {
    return (
      <Link to={"/catalog/"} state={category} className="under-link">
        <div className="under-cont">
          <span className="under-txt">{txt}</span>
        </div>
      </Link>
    );
  }

  return (
    <>
      <div className="window">
        <div className="window-content">
          <div className="caty-bars">
            {CategoryBar("Косметика", <CategoryBeuty className="caty-img" />, "cosmetic")}
            {CategoryBar("Бытовая химия", <CategoryChym className="caty-img" />, "chemistry")}
            {CategoryBar("Продукты питания", <CategoryEat className="caty-img" />, "eat")}
            {CategoryBar("Товары для детей", <CategoryChild className="caty-img" />, "kid")}
            {CategoryBar("Одежда и обувь", <CategoryColth className="caty-img" />, "colth")}
          </div>
          <div className="under-caty">
            {UnderCategory("Все", "all")}
            {UnderCategory("Парфюмерия", "all")}
            {UnderCategory("Одежда", "all")}
            {UnderCategory("Косметика", "all")}
            {UnderCategory("Уход за лицом", "all")}
            {UnderCategory("Уход за телом", "all")}
            {UnderCategory("Уход за волосами", "all")}
            {UnderCategory("Средства гигиены", "all")}
          </div>

          <h1 className="text-category-page mt100">{data}</h1>

            {userStorage ? (
              <Filter userStorage={(userStorage)} />
            ): null}
            

        </div>
        <Footer />
      </div>
    </>
  );
};

export default Catalog;

