import React, { Component } from 'react';
import './Cookie.css';

export class CookieBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBanner: true
    };
  }

  closeBanner = () => {
    this.setState({ showBanner: false });
  };

  render() {
    return (
      <>
        {this.state.showBanner && (
          <div className="cookie-banner">
            <p>
              Мы используем файлы cookie, чтобы улучшить ваше пользовательское впечатление.
            </p>
            <button onClick={this.closeBanner} className="close-button">
              Окей
            </button>
          </div>
        )}
      </>
    );
  }
}


