import React, { Component } from "react";
import { Footer } from "./Footer";
import { UploadTable } from "./UploadStorageFolder/UploadTable";
import { Tables } from "./UploadStorageFolder/Tables";
import "./NavMenu.css";
import { data } from "jquery";

export class UploadStorage extends Component {
  constructor(){
    super();
    this.state = {
      userTable: null
    };
  }


  async GetUserTable() {
    const hash = localStorage.getItem("sessionId")
    const URLHASH = "/api/storage/" + hash + "/table";
    return fetch(URLHASH)
      .then(response => (
        response.json()
      ))
      .then(data => 
        this.setState({userTable: data}))
      .catch((error) => console.error(error));
  }

  async componentDidMount() {
    this.GetUserTable();
  }

  render() {

    const { userTable } = this.state;

    return (
      <>
        <div className="window">
          <div className="window-content-tables">
            {userTable && userTable ? <Tables tabs={userTable}/> : <UploadTable/> }
          </div>
        </div>
        <Footer />
      </>
    );
  }
}
