import React, { Component } from "react";
import "./PersonalData.css";
import { ReactComponent as Miniarrowright } from "../components/svgs/mini-arrow-right.svg";
import { Footer } from "./Footer";

import { ReactComponent as Ok } from "../components/svgs/status-ok.svg";
import { ReactComponent as Waring } from "../components/svgs/waring.svg";

export class PersonalData extends Component {
  constructor() {
    super();
    this.state = {
      userData: null,
      userType: null,
      Datas: false,
    };
  }
  handleRouteMore = () => {
    window.location.href = "/personal-data/more";
  };
  async savePesonalData(hash) {
    const URLREG = "/api/client/saved";

    const phone = document.querySelector("#phone").value;
    const email = document.querySelector("#email").value;
    const name = document.querySelector("#name").value;

    const newClient = {
      ip: "",
      email: email,
      name: name,
      password: "",
      phone: phone,
      hash: hash,
    };

    const options = {
      method: "POST",

      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },

      body: JSON.stringify(newClient),
    };

    const result = await fetch(URLREG, options);

    if (result.ok) {
      const resp = await result.json();

      if (resp === true) {
        window.location.href = "/personal-data";
      } else {
        console.log(resp);
        alert("Ошибка: Неудачная регистрация");
      }
    }
  }

  async getPersonData(hash) {
    const URLHASH = "/api/client/" + hash + "/cabinet";
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ userData: data });
      })
      .catch((error) => console.error(error));
  }

  async getNewHash() {
    const URLHASH = "/api/client/hash";
    return fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("sessionId", data);
        return data;
      })
      .catch((error) => console.error(error));
  }

  async componentDidMount() {
    const hash = localStorage.getItem("sessionId") || (await this.getNewHash());
    this.getPersonData(hash);
  }

  async savePesonalDataLogout() {
    localStorage.removeItem("sessionId");
    window.location.href = "/";
  }

  render() {
    const { userData } = this.state;
    const { Datas } = this.state;

    function Avatar() {
      const URLHASH = "/photoloader/avatar";
      fetch(URLHASH)
         .then((res) => res.text('base64'))
         .then((data) => {
          return (
            <>
              <img src={"data:image/png;base64," +  data} />
            </>
          );
         })
         .catch((err) => {
            console.log(err.message);
         });
      
      
    }

    function dataVaidation() {
      if (userData.phone == "") return;
      if (userData.lastName == "") return;
      if (userData.firstName == "") return;

      return true;
    }

    return (
      <>
        <div className="window">
          <div className="window-content">
            <div className="pers-nav-body">
              <a className="nav" href="/">
                Главная
              </a>

              <Miniarrowright className="arrow-right" />
              <a className="nav" href="/personal-data">
                Личный кабинет
              </a>
            </div>

            <div className="pers-header">
              <h1 className="text-page bold mt30">Личный кабинет</h1>
            </div>

            <div className="pers-block">
              {userData && (
                <div className="user-details">
                  <div className="box-person">
                    <div className="avatar">
                      <Avatar />
                    </div>
                    {userData && (
                      <h2 className="avatar-name">
                        {userData.firstName + " " + userData.lastName}
                      </h2>
                    )}
                  </div>
                  <div className="button-box">
                    <p className="up-title"> </p>
                    <input
                      onClick={() => this.handleRouteMore()}
                      type="button"
                      className="input-data"
                      value={"Данные аккаунта"}
                    ></input>
                    {dataVaidation() ? (
                      <Ok className="status" />
                    ) : (
                      <Waring className="status" />
                    )}
                    <input
                      type="button"
                      className="input-log-out"
                      value={"Выйти"}
                      onClick={() =>
                        this.savePesonalDataLogout(
                          localStorage.getItem("sessionId")
                        )
                      }
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="purchases-header">
              <h1 className="text-page bold mt30">Покупки</h1>
            </div>
            <div className="purchases-block">
              <div className="user-details">
                <a className="pers-url" href="/liked">
                  Избранное
                </a>
                <a className="pers-url" href="/wait">
                  Заказы
                </a>
                <a className="pers-url" href="/wait">
                  Купленные товары
                </a>
                <a className="pers-url" href="/wait">
                  Промкоды
                </a>
                <a className="pers-url" href="/wait">
                  Сравнение товаров
                </a>
                <a className="pers-url" href="/wait">
                  Отзывы
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
