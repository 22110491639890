import { Component } from "react";

export class Waiting extends Component {
  render() {
    return (
      <>
        <div className="window">
          <div className="window-content">
            <h1 className="text-page bold mt80">Этот раздел еще не подключен, зайдите сюда чуть позже</h1>
          </div>
        </div>
      </>
    );
  }
}
