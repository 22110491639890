import React, { Component } from "react";
import "./PersonalData.css";
import { ReactComponent as Miniarrowright } from "../components/svgs/mini-arrow-right.svg";
import { Footer } from "./Footer";

import { ReactComponent as Ok } from "../components/svgs/status-ok.svg";
import { ReactComponent as Waring } from "../components/svgs/waring.svg";

export class PersonalDataMore extends Component {
  constructor() {
    super();
    this.state = {
      type: true,
      userData: null,
      userType: null,
    };
  }

  handleTypeBuyer(e) {
    this.state.type = true;
  }

  handleTypeSeller(e) {
    this.state.type = false;
  }

  async savePesonalData(hash) {
    const URLREG = "/api/client/saved";

    const Phone = document.querySelector("#phone").value;
    const Email = document.querySelector("#email").value;
    const FirstName = document.querySelector("#first-name").value;
    const LastName = document.querySelector("#last-name").value;

    const newClient = {
      Ip: "",
      Email: Email,
      FirstName: FirstName,
      LastName: LastName,
      Password: "",
      Phone: Phone,
      Hash: hash,
    };

    const options = {
      method: "POST",

      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
      },

      body: JSON.stringify(newClient),
    };

    const result = await fetch(URLREG, options);

    if (result.ok) {
      const resp = await result.json();

      if (resp === true) {
        window.location.href = "/personal-data";
      } else {
        console.log(resp);
        alert("Ошибка: Неудачная регистрация");
      }
    }
  }

  async getPersonData(hash) {
    const URLHASH = "/api/client/" + hash + "/cabinet";
    fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        this.setState({ userData: data });
      })
      .catch((error) => console.error(error));
  }

  async getNewHash() {
    const URLHASH = "/api/client/hash";
    return fetch(URLHASH)
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem("sessionId", data);
        return data;
      })
      .catch((error) => console.error(error));
  }

  async componentDidMount() {
    const hash = localStorage.getItem("sessionId") || (await this.getNewHash());
    this.getPersonData(hash);
  }

  async savePesonalDataLogout() {
    localStorage.removeItem("sessionId");
    window.location.href = "/";
  }

  render() {
    const { userData } = this.state;

    function ButtonType() {
      console.log(userData.type)
      if (userData.type == "store") {
        return (
          <>
            <button className={"type-button"}>Магазин</button>
          </>
        );
      } else {  
        return (
          <>
            <button className={"type-button"}>Поставщик</button>
          </>
        );
      } 
    }

    return (
      <>
        <div className="window">
          <div className="window-content">
            <div className="pers-nav-body">
              <a className="nav" href="/">
                Главная
              </a>

              <Miniarrowright className="arrow-right" />
              <a className="nav" href="/personal-data">
                Личный кабинет
              </a>
            </div>

            <div className="pers-header">
              <h1 className="text-page bold mt30">Данные аккаунта</h1>
            </div>

            <div className="pers-block">
              {userData && (
                <div className="user-details">
                  <div>
                    <div className="test">
                      <p className="up-title">Имя</p>
                      <input
                        id="first-name"
                        className="input"
                        defaultValue={userData.firstName}
                      ></input>
                      {userData.firstName ? (
                        <Ok className="status left" />
                      ) : (
                        <Waring className="status left" />
                      )}

                      <p className="up-title">Фамилия</p>
                      <input
                        id="last-name"
                        className="input"
                        defaultValue={userData.lastName}
                      ></input>
                      {userData.lastName ? (
                        <Ok className="status" />
                      ) : (
                        <Waring className="status" />
                      )}
                      <p className="up-title">Почта</p>
                      <input
                        id="email"
                        className="input"
                        defaultValue={userData.email}
                      ></input>
                      {userData.email ? (
                        <Ok className="status" />
                      ) : (
                        <Waring className="status" />
                      )}

                      <p className="up-title">Номер телефона </p>
                      <input
                        id="phone"
                        className="input"
                        defaultValue={userData.phone}
                      ></input>
                      {userData.phone ? (
                        <Ok className="status" />
                      ) : (
                        <Waring className="status" />
                      )}
                      <p className="up-title"> </p>
                    </div>
                    <div className="test">
                      <p className="up-title">Тип аккаунта</p>
                      <div className="input-type-box">
                        <ButtonType />
                      </div>
                    </div>

                    <div className="button-box">
                      <input
                        type="button"
                        className="input-pass"
                        value={"Изменить пароль"}
                      ></input>

                      <p className="up-title"> </p>
                      <input
                        type="button"
                        className="input-save"
                        value={"Сохранить"}
                        onClick={() =>
                          this.savePesonalData(
                            localStorage.getItem("sessionId")
                          )
                        }
                      ></input>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="purchases-header">
              <h1 className="text-page bold mt30">Покупки</h1>
            </div>
            <div className="purchases-block">
              <div className="user-details">
                <a className="pers-url" href="/liked">
                  Избранное
                </a>
                <a className="pers-url" href="/wait">
                  Заказы
                </a>
                <a className="pers-url" href="/wait">
                  Купленные товары
                </a>
                <a className="pers-url" href="/wait">
                  Промкоды
                </a>
                <a className="pers-url" href="/wait">
                  Сравнение товаров
                </a>
                <a className="pers-url" href="/wait">
                  Отзывы
                </a>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </>
    );
  }
}
