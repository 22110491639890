import { Counter } from "./components/Counter";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { Faq } from "./components/Faq";
import { PersonalData } from "./components/PersonalData";
import { PersonalDataMore } from "./components/PersonalDataMore";
import { MyStorage } from "./components/MyStorage";
import { AddStorage} from "./components/AddStorage";
import { UploadStorage} from "./components/UploadStorage";
import { Waiting } from "./components/Waiting";
import { Liked } from "./components/Liked";
import Catalog  from "./components/Catalog";
import React from 'react';

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path: '/counter',
    element: <Counter />
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/faq',
    element: <Faq />
  },
  {
    path: '/personal-data',
    element: <PersonalData />
  },
  {
    path: '/personal-data/more',
    element: <PersonalDataMore />
  },
  {
    path: '/catalog',
    element: <Catalog />
  },
  {
    path: '/storage',
    element: <MyStorage />
  },
  {
    path: '/storage/add',
    element: <AddStorage />
  },
  {
    path: '/storage/upload',
    element: <UploadStorage />
  },
  {
    path: '/wait',
    element: <Waiting />
  },
  {
    path: '/liked',
    element: <Liked />
  },
];

export default AppRoutes;
